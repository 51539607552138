import "./App.css"
import Emoji from "./Items/Emoji"

const cards = [
	{
		name: "Twitter",
		link: "http://twitter.com/wobblypictures",
		emoji: "woozy-face",
	},
	{
		name: "GitHub",
		link: "http://github.com/lukevanlukevan",
		emoji: "keyboard",
	},
	{
		name: "Instagram",
		link: "http://instagram.com/wobblypictures",
		emoji: "monitor",
	},
	{
		name: "Patreon",
		link: "http://patreon.com/lukevanlukevan",
		emoji: "bulb",
	},
	{
		name: "PIC - Lab",
		link: "https://studio-pic.web.app/",
		emoji: "tube",
	},
]

function ListCard(props) {
	return (
		// <div className="cardItem">
		<a
			href={props.link}
			target="_blank"
			rel="noreferrer"
			className="cardItem"
		>
			<Emoji name={props.emoji} />
			<h3 className="cardTitle">{props.name}</h3>
		</a>
		// </div>
	)
}

function App() {
	return (
		<div className="App">
			<header className="App-header"></header>
			{cards.map((card) => {
				console.log("here")
				return (
					<ListCard
						name={card.name}
						link={card.link}
						key={card.name}
						emoji={card.emoji}
					/>
				)
			})}
		</div>
	)
}

export default App

